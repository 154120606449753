import { Loader } from "@googlemaps/js-api-loader"

(function () {
  const mapContainer = document.getElementById("map-container")
  if (!mapContainer) {
    return;
  }

  let map = null

  const loader = new Loader({
    apiKey: "AIzaSyCqoL5IdlCs8gF7dcgaVcI3_-bHqM9JVOk",
    version: "weekly",
  });
  loader.load().then(() => {
    const markerPos = { lat: 56.96199397540692, lng: 24.080972984461415 }
    const centerPos = { lat: 56.95206637385603, lng: 24.098257139031677}
    map = new google.maps.Map(document.getElementById("map-container"), {
      center: centerPos,
      zoom: 12,
      mapId: 'fc3649677ade2275',
      disableDefaultUI: true,
    });
    const marker = new google.maps.Marker({
      position: markerPos,
      map: map,
    });
    marker.addListener('click', () => {
      window.open("https://goo.gl/maps/oFrzVb9hYh578tnj9", "_blank")
    })
  });

})()